import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  // Inject the current `AuthService` and use it to get an authentication token:
  const TOKEN_HEADER_KEY = 'Authorization';
  const authToken = inject(AuthenticationService).getToken();
  // Clone the request to add the authentication header.

  if (authToken) {
    const newHeader = req.headers.append(TOKEN_HEADER_KEY, authToken as string);
    const newReq = req.clone({ headers: newHeader });
    return next(newReq);
  } else {
    return next(req);
  }
}
