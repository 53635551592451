import { Routes } from '@angular/router';
import { MenuLayoutComponent } from './layouts/menu-layout/menu-layout.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { PAGE_ROUTER } from './constants/page-router.constants';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: MenuLayoutComponent,
    loadChildren: () =>
      import('./modules/authentication/authentication.module').then(
        (module) => module.AuthenticationModule
      ),
  },
  {
    path: PAGE_ROUTER.home,
    canActivateChild: [AuthGuard],
    component: MenuLayoutComponent,
    loadChildren: () =>
      import('./modules/home/home.module').then(
        (module) => module.HomeModule
      ),
  },
  {
    path: PAGE_ROUTER.admin_tools,
    canActivateChild: [AuthGuard],
    component: MenuLayoutComponent,
    loadChildren: () =>
      import('./modules/admin/admin.module').then(
        (module) => module.AdminModule
      ),
  },
  {
    path: PAGE_ROUTER.files,
    canActivateChild: [AuthGuard],
    component: MenuLayoutComponent,
    loadChildren: () =>
      import('./modules/files/files.module').then(
        (module) => module.FilesModule
      ),
  },
  {
    path: PAGE_ROUTER.reports,
    canActivateChild: [AuthGuard],
    component: MenuLayoutComponent,
    loadChildren: () =>
      import('./modules/reports/reports.module').then(
        (module) => module.ReportsModule
      ),
  },

];
