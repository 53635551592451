<div class="menu-wrapper fixed-top d-flex justify-content-between">
  <div class="logo d-flex">
    <img src="../../../assets/GM-logo.png" />
    <h1>Dealer Billing Portal</h1>
  </div>
  <div class="menu d-flex align-items-center" *ngIf="menuList.length > 0">
    @for(menu of menuList; track menu) {
      @if(menu.id !== 'reports') {
        <a [routerLink]="getNavLink(pageRouter[menu.id])">{{menu.label}}</a>
      } @else {
        <a [matMenuTriggerFor]="reports" class="report-menu d-flex align-items-center">Reports <mat-icon class="report-menu" iconPositionEnd>expand_more</mat-icon></a>
      }
    }
    <a>
      <div>
        <div class="user-name-wrapper d-flex" [matMenuTriggerFor]="menu">
          <mat-icon class="user-icon">account_circle</mat-icon>
          <span class="user-name-text">{{userDetails}}</span>
          <mat-icon class="user-dropdown-icon" iconPositionEnd>expand_more</mat-icon>
        </div>

        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item class="logout-btn" (click)="logoutUser()">Logout</button>
        </mat-menu>
        
      </div>
    </a>
  </div>
</div>


<mat-menu #reports="matMenu" xPosition="before" class="main-sub-menus">
  <a [routerLink]="getNavLink(pageRouter['bars_payment_reports'])">BARS Payment Reports</a>
  <a [routerLink]="getNavLink(pageRouter['reconciliation_reports'])">Reconciliation Reports</a>
  <a [routerLink]="getNavLink(pageRouter['coop_imr_enrolled_dealers'])">Coop_IMR Enrolled Dealers</a>
</mat-menu>

<div class="content-wrapper ">
  <router-outlet></router-outlet>
</div>
<footer class="fixed-bottom bg-drak-blue fixed-bottom d-print-none">
  <div class="d-flex justify-content-between px-3 pt-2 w-100">
    <div class="sec-1 d-flex ">
      <a href="https://www.gm.com/privacy-statement" target="_blank">GM Privacy Policy |</a>
      <a href="https://www.acbcoop.com/privacy-policy/" target="_blank">Privacy Policy |</a>
      <a href="https://www.acbcoop.com/cookie-policy/" target="_blank">Cookie Policy </a>
    </div>

    <div class="sec-3 d-flex">
      <p><i class="material-icons" id="btncopyright">copyright</i><span class="year">{{todayDate | date :
          'YYYY'}}</span> General Motors LLC All Rights Reserved</p>
    </div>
  </div>
</footer>