import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from '../../modules/authentication/services/authentication.service';
import { PAGE_ROUTER, getNavLink } from '../../constants/page-router.constants';
import {MatMenuModule} from '@angular/material/menu';

@Component({
  selector: 'app-menu-layout',
  standalone: true,
  imports: [RouterModule,
    CommonModule,
    MatIconModule,
    MatMenuModule
    

  ],
  templateUrl: './menu-layout.component.html',
  styleUrl: './menu-layout.component.scss'
})
export class MenuLayoutComponent implements OnInit {
  todayDate: Date = new Date();
  menuList: any[] = [];
  name: any;
  pageRouter : any = PAGE_ROUTER;
  userDetails:any;
  getNavLink =  getNavLink;
  userMenuList: any = [
    {
      id: '',
      title: 'Change Password',
      icon: 'lock',
      url: '/auth/change-password',
    },
  ];
  constructor(private authenticationService: AuthenticationService) {

  }
  
  ngOnInit(): void {
    this.menuList = this.authenticationService.getMenuList();
    if(this.authenticationService.getToken()){
      this.userDetails = this.authenticationService.getUserObj().firstName + " " + this.authenticationService.getUserObj().lastName;
    }
   
  }
  logoutUser() {
    this.authenticationService.logOutUser();
  }
  



}